import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import { DataMask, CpfMask, CnpjMask } from '../../../../vendor/mascaras';
import moment from 'moment';



export default function ModalNovaPessoa({ visible, change, atualizarPessoas}) {
  

  let history = useHistory();

  const [loaderPage, setloaderPage] = useState(false);
  const [step, setstep] = useState(0);
  const [errorStep1, seterrorStep1] = useState(false);

  const [dataNascimento, setdataNascimento] = useState(null);
  const [dataFundacao, setdataFundacao] = useState(null);
  const [dadosPessoa, setdadosPessoa] = useState([]);
  const [dadosEmpresa, setdadosEmpresa] = useState([]);

  const handleChangeDataNascimento = (data) => {
    setdataNascimento(data);
  };

  const handleChangeDataFundacao = (data) => {
    setdataFundacao(data);
  };

  const validarCPF = (cpf) => {
    if (!cpf) return true; // Não é obrigatório, então passa se for vazio

    // Remove caracteres especiais
    const cpfLimpo = cpf.replace(/[^\d]/g, '');

    // Verifica se tem 11 dígitos e se não é uma sequência repetida
    if (cpfLimpo.length !== 11 || /^(\d)\1+$/.test(cpfLimpo)) return false;

    // Calcula os dígitos verificadores
    const calcularDigito = (cpf, fator) => {
        let soma = 0;
        for (let i = 0; i < fator - 1; i++) {
            soma += parseInt(cpf[i]) * (fator - i);
        }
        const resto = (soma * 10) % 11;
        return resto === 10 ? 0 : resto;
    };

    const digito1 = calcularDigito(cpfLimpo, 10);
    const digito2 = calcularDigito(cpfLimpo, 11);

    return digito1 === parseInt(cpfLimpo[9]) && digito2 === parseInt(cpfLimpo[10]);
  };

  const validarCNPJ = (cnpj) => {
    if (!cnpj) return true; // Não é obrigatório, então passa se for vazio

    // Remove caracteres especiais
    const cnpjLimpo = cnpj.replace(/[^\d]/g, '');

    // Verifica se tem 14 dígitos e se não é uma sequência repetida
    if (cnpjLimpo.length !== 14 || /^(\d)\1+$/.test(cnpjLimpo)) return false;

    // Calcula os dígitos verificadores
    const calcularDigito = (cnpj, fatores) => {
        let soma = 0;
        for (let i = 0; i < fatores.length; i++) {
            soma += parseInt(cnpj[i]) * fatores[i];
        }
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
    };

    // Fatores para o primeiro e segundo dígitos
    const fatores1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const fatores2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    const digito1 = calcularDigito(cnpjLimpo, fatores1);
    const digito2 = calcularDigito(cnpjLimpo, fatores2);

    // Verifica os dois dígitos verificadores
    return digito1 === parseInt(cnpjLimpo[12]) && digito2 === parseInt(cnpjLimpo[13]);
  };
  
  const validationSchema = yup.object({
    tipo: yup
      .number('Selecione o tipo de pessoa')
      .required('Tipo Obrigatório'),
    nome: yup
      .string('Insira o nome da pessoa')
      .required('Nome Obrigatório'),
    // email: yup
    //   .string('Insira seu E-mail')
    //   .email('Insira um E-mail válido')
    //   .required('E-mail Obrigatório'),
    rg: yup
      .string('Insira o RG'),
    cpf: yup
      .string('Insira o CPF')
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF deve estar no formato 000.000.000-00') // Valida o formato
      .test('validar-cpf', 'CPF inválido', (value) => validarCPF(value)),
    nascimento: yup
      .string('Insira a data de nascimento'),
    cnpj: yup
      .string('Insira o CNPJ')
      .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ deve estar no formato 00.000.000/0000-00') // Valida o formato
      .test('validar-cnpj', 'CNPJ inválido', (value) => !value || validarCNPJ(value)),
    representante: yup
      .string('Insira o nome do representante da empresa'),
    inscricaoestadual: yup
      .string('Insira a inscrição estadual da empresa'),
    inscricaomunicipal: yup
      .string('Insira a inscrição municipal da empresa'),
  });

  const formik = useFormik({
    initialValues: {
      tipo: '',
      nome: '',
      email: '',
      nacionalidade: '',
      nomePai: '',
      nomeMae: '',
      rg: '',
      cpf: '',
      nascimento: '',
      cnpj: '',
      representante: '',
      inscricaoestadual: '',
      inscricaomunicipal: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      step < 1 && setstep(step+1)

      if(step === 1){ //TUDO CERTO PRA CADASTRAR
               
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`addPessoa`, {
            "tipo": values.tipo,
            "nome": values.nome,
            "email": values.email,
            "nacionalidade": values.nacionalidade,
            "nomePai": values.nomePai,
            "nomeMae": values.nomeMae,
            "cpf": values.cpf,
            "rg":  values.rg,
            "nascimento": dataNascimento,
            "dataFundacao": dataFundacao,
            "cnpj": values.cnpj,
            "inscricaoestadual": values.inscricaoestadual,
            "inscricaomunicipal": values.inscricaomunicipal,
            "representante": values.representante
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        atualizarPessoas(null, response.data.codigo.toString());
        formik.resetForm();
        setstep(0);
        change(false)

      }      
    }
  });

  useEffect(()=>{
    let erros = JSON.stringify(formik.errors);
    erros = erros.length - 2;

    if(erros === 0){
      seterrorStep1(false);
    }else{
      seterrorStep1(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.errors]);

  const handleCPF = async()=>{
    
    let cpf = formik.values.cpf;

    // Remove caracteres não numéricos do CPF para validação
    const cpfLimpo = cpf.replace(/[^\d]/g, '');

    // Verifica se o CPF tem 11 caracteres e é válido
    if (cpfLimpo.length !== 11 || !validarCPF(cpfLimpo)) {
        console.log("CPF inválido ou incompleto");
        return;
    }

    setloaderPage(true);
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post(`consultaCPF`, {
        "cpf": cpf,
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    response = response.data;


    const extendedInfo = response?.ExtendedDocumentInformation;
    let rg = "";

    if (extendedInfo && Object.values(extendedInfo).length > 0) {
        const documents = Object.values(extendedInfo).map(info => info?.DocumentNumber);
        rg = documents[0] || ""; // Pega o primeiro valor válido ou mantém `null`
    }

    const nascimento = moment.utc(response?.BirthDate).format('YYYY/MM/DD');
   
    formik.setFieldValue('nome', response?.Name);
    formik.setFieldValue('rg', rg);
    formik.setFieldValue('nacionalidade', response?.BirthCountry);
    formik.setFieldValue('nomePai', response?.FatherName);
    formik.setFieldValue('nomeMae', response?.MotherName);
    response?.BirthDate && setdataNascimento(nascimento);

    setdadosPessoa(response);
    setloaderPage(false);
  }

  const handleCNPJ = async()=>{
    
    let cnpj = formik.values.cnpj;

    // Remove caracteres não numéricos do CNPJ para validação
    const cnpjLimpo = cnpj.replace(/[^\d]/g, '');

    // Verifica se o CNPJ tem 14 caracteres e é válido
    if (cnpjLimpo.length !== 14 || !validarCNPJ(cnpjLimpo)) {
        console.log("CNPJ inválido ou incompleto");
        return;
    }

    setloaderPage(true);
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post(`consultaCNPJ`, {
        "cnpj": cnpj,
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    response = response.data;
    const fundacao = moment.utc(response?.data_inicio_atividade).format('YYYY/MM/DD');
   
    formik.setFieldValue('nome', response?.razao_social);
    formik.setFieldValue('representante', response?.qsa[0]?.nome_socio || '');
    response?.email && formik.setFieldValue('email', response?.email);
    response?.data_inicio_atividade && setdataFundacao(fundacao);

    setdadosEmpresa(response);
    setloaderPage(false);
  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
        <DialogTitle sx={{ m: 0, p: 0 }}>
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={0} >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '50px' }}>
                <Stepper activeStep={step} alternativeLabel>
                    <Step>
                    <StepLabel error={errorStep1}>Pessoa</StepLabel>
                    </Step>
                    <Step>
                    <StepLabel error={false}>Detalhes</StepLabel>
                    </Step>
                    <Step>
                    <StepLabel error={false}>Finalizar</StepLabel>
                    </Step>
                </Stepper>

                <form method='post' onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: '40px', paddingRight: '40px', paddingBottom: '40px' }}>
                    <h2 style={{ color: '#9C9C9C', fontWeight: 'normal', marginTop: '50px' }}>Cadastro de pessoa</h2>
                    
                    {
                        step === 0 &&

                        <>
                        <FormControl sx={{ m: 1, width: '100%' }} >
                            <InputLabel id="label-tipo">Tipo</InputLabel>
                            <Select
                            className='input'
                            labelId="label-tipo"
                            id="demo-simple-select"
                            name="tipo"
                            label="Tipo"
                            placeholder='Tipo'
                            value={formik.values.tipo}
                            onChange={formik.handleChange}
                            error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                            >
                            <MenuItem value={1}>Física</MenuItem>
                            <MenuItem value={2}>Jurídica</MenuItem>
                            </Select>
                            <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                        </FormControl>

                        {
                          formik.values.tipo === 1 ? 
                          <>
                            <TextField
                              className='input'
                              fullWidth
                              id="cpf"
                              name="cpf"
                              label="CPF"
                              margin="normal"
                              type="text"
                              value={formik.values.cpf}
                              onChange={formik.handleChange}
                              onBlur={()=>{ handleCPF() }}
                              error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                              helperText={formik.touched.cpf && formik.errors.cpf}
                              InputProps={{
                                inputComponent: CpfMask,
                              }}
                            />

                            <TextField
                              className='input'
                              fullWidth
                              id="nome"
                              name="nome"
                              label="nome"
                              margin="normal"
                              type="text"
                              value={formik.values.nome}
                              onChange={formik.handleChange}
                              error={formik.touched.nome && Boolean(formik.errors.nome)}
                              helperText={formik.touched.nome && formik.errors.nome}
                            />

                            <TextField
                              className='input'
                              fullWidth
                              id="email"
                              name="email"
                              label="E-mail"
                              margin="normal"
                              type="text"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                              helperText={formik.touched.email && formik.errors.email}
                            />
                          </>

                          : formik.values.tipo === 2 ? 

                          <>
                            <TextField
                              className='input'
                              fullWidth
                              id="cnpj"
                              name="cnpj"
                              label="CNPJ"
                              margin="normal"
                              type="text"
                              value={formik.values.cnpj}
                              onChange={formik.handleChange}
                              onBlur={()=>{ handleCNPJ() }}
                              error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                              helperText={formik.touched.cnpj && formik.errors.cnpj}
                              InputProps={{
                                inputComponent: CnpjMask,
                              }}
                            />

                            <TextField
                              className='input'
                              fullWidth
                              id="nome"
                              name="nome"
                              label="nome"
                              margin="normal"
                              type="text"
                              value={formik.values.nome}
                              onChange={formik.handleChange}
                              error={formik.touched.nome && Boolean(formik.errors.nome)}
                              helperText={formik.touched.nome && formik.errors.nome}
                            />

                            <TextField
                              className='input'
                              fullWidth
                              id="email"
                              name="email"
                              label="E-mail"
                              margin="normal"
                              type="text"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                              helperText={formik.touched.email && formik.errors.email}
                            />
                          </>

                          :

                          <></>
                        }

                        </>

                    }

                    {
                      step === 1 &&

                      <>
                        {
                          formik.values.tipo === 1 ? 

                          <>
                          
                            <TextField
                              className='input'
                              fullWidth
                              id="nacionalidade"
                              name="nacionalidade"
                              label="Nacionalidade"
                              margin="normal"
                              type="text"
                              value={formik.values.nacionalidade}
                              onChange={formik.handleChange}
                              error={formik.touched.nacionalidade && Boolean(formik.errors.nacionalidade)}
                              helperText={formik.touched.nacionalidade && formik.errors.nacionalidade}
                            />
      
                            <TextField
                              className='input'
                              fullWidth
                              id="rg"
                              name="rg"
                              label="RG"
                              margin="normal"
                              type="text"
                              value={formik.values.rg}
                              onChange={formik.handleChange}
                              error={formik.touched.rg && Boolean(formik.errors.rg)}
                              helperText={formik.touched.rg && formik.errors.rg}
                            />

                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                              <DesktopDatePicker
                                label="Data de Nascimento"
                                inputFormat="dd/MM/yyyy"
                                name="nascimento"
                                value={dataNascimento}
                                onChange={handleChangeDataNascimento}
                                renderInput={(params) => <TextField className='input' style={{ width: '100%', marginTop: '15px'}} {...params} />}
                              />
                            </LocalizationProvider>

                            <TextField
                              className='input'
                              fullWidth
                              id="nomePai"
                              name="nomePai"
                              label="Nome do pai"
                              margin="normal"
                              type="text"
                              value={formik.values.nomePai}
                              onChange={formik.handleChange}
                              error={formik.touched.nomePai && Boolean(formik.errors.nomePai)}
                              helperText={formik.touched.nomePai && formik.errors.nomePai}
                            />
                          
                            <TextField
                              className='input'
                              fullWidth
                              id="nomeMae"
                              name="nomeMae"
                              label="Nome da mãe"
                              margin="normal"
                              type="text"
                              value={formik.values.nomeMae}
                              onChange={formik.handleChange}
                              error={formik.touched.nomeMae && Boolean(formik.errors.nomeMae)}
                              helperText={formik.touched.nomeMae && formik.errors.nomeMae}
                            />

                          </>

                          : formik.values.tipo === 2 &&

                          <>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                              <DesktopDatePicker
                                label="Data de Fundação"
                                inputFormat="dd/MM/yyyy"
                                name="dataFundacao"
                                value={dataFundacao}
                                onChange={handleChangeDataFundacao}
                                renderInput={(params) => <TextField className='input' style={{ width: '100%', marginTop: '15px'}} {...params} />}
                              />
                            </LocalizationProvider>

                            <TextField
                              className='input'
                              fullWidth
                              id="inscricaoestadual"
                              name="inscricaoestadual"
                              label="Inscrição estadual"
                              margin="normal"
                              type="text"
                              value={formik.values.inscricaoestadual}
                              onChange={formik.handleChange}
                              error={formik.touched.inscricaoestadual && Boolean(formik.errors.inscricaoestadual)}
                              helperText={formik.touched.inscricaoestadual && formik.errors.inscricaoestadual}
                            />

                            <TextField
                              className='input'
                              fullWidth
                              id="inscricaomunicipal"
                              name="inscricaomunicipal"
                              label="Inscrição municipal"
                              margin="normal"
                              type="text"
                              value={formik.values.inscricaomunicipal}
                              onChange={formik.handleChange}
                              error={formik.touched.inscricaomunicipal && Boolean(formik.errors.inscricaomunicipal)}
                              helperText={formik.touched.inscricaomunicipal && formik.errors.inscricaomunicipal}
                            />


                            <TextField
                              className='input'
                              fullWidth
                              id="representante"
                              name="representante"
                              label="Nome completo do Representante"
                              margin="normal"
                              type="text"
                              value={formik.values.representante}
                              onChange={formik.handleChange}
                              error={formik.touched.representante && Boolean(formik.errors.representante)}
                              helperText={formik.touched.representante && formik.errors.representante}
                            />

                            <TextField
                              className='input'
                              fullWidth
                              id="rg"
                              name="rg"
                              label="RG do Representante"
                              margin="normal"
                              type="text"
                              value={formik.values.rg}
                              onChange={formik.handleChange}
                              error={formik.touched.rg && Boolean(formik.errors.rg)}
                              helperText={formik.touched.rg && formik.errors.rg}
                            />

                            <TextField
                              className='input'
                              fullWidth
                              id="cpf"
                              name="cpf"
                              label="CPF do Representante"
                              margin="normal"
                              type="text"
                              value={formik.values.cpf}
                              onChange={formik.handleChange}
                              error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                              helperText={formik.touched.cpf && formik.errors.cpf}
                              InputProps={{
                                inputComponent: CpfMask,
                              }}
                            />

                          </>

                        }
                      </>

                    }

                    <div className='box-buttons-steps'>
                        {
                        step > 0 &&

                        <div className='box-tbn-steps'>
                            <ButtonOutlineSecondary type="button" icon="" txt="Voltar" onClick={ ()=>{  step > 0 && setstep(step-1)  } } />
                        </div>
                        }

                        <div className='box-tbn-steps'>
                            <ButtonDefault type="submit" icon="" txt={  step < 1 ? 'Continuar' : 'Finalizar'} onClick={ ()=>{  } } />
                        </div>
                    </div>
                </form>
            </div>
          </Grid>

        </DialogContent>
    </Dialog>
    </>
  );

}