import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from "../pages/Login";
import RecoveryAccount from "../pages/RecoveryAccount";

import EmailInbox from '../pages/Email/inbox';

import Resumo from "../pages/Resumo";
import Pessoas from "../pages/Cadastros/Pessoas";
import NovaPessoa from "../pages/Cadastros/Pessoas/novaPessoa";
import PessoasDetalhes from '../pages/Cadastros/Pessoas/pessoasDetalhes';
import NovoUsuario from '../pages/Cadastros/Usuarios/novoUsuario';
import UsuarioDetalhes from '../pages/Cadastros/Usuarios/usuarioDetalhes';
import Veiculos from "../pages/Cadastros/Veiculos";
import NovoVeiculo from '../pages/Cadastros/Veiculos/novoVeiculo';
import NovoVeiculoPlaca from '../pages/Cadastros/Veiculos/novoVeiculoPlaca';
import NovoVeiculoPlacaV2 from '../pages/Cadastros/Veiculos/novoVeiculoPlacaV2';
import VeiculosDetalhes from '../pages/Cadastros/Veiculos/veiculosDetalhes';
import Contratos from "../pages/Cadastros/Contratos";
import ContratoDetalhes from '../pages/Cadastros/Contratos/contratoDetalhes';
import ModeloMensagem from '../pages/Cadastros/ModeloMensagens';
import ListasTransmissoes from '../pages/Cadastros/ListasTransmissoes';
import Estoque from '../pages/Estoque';
import EstoqueDetalhes from '../pages/Estoque/estoqueDetalhes';
import NovaCompra from '../pages/Estoque/novaCompra';
import NovaVenda from '../pages/Estoque/novaVenda';
import FluxoCaixa from '../pages/Financas/fluxoCaixa';
import ControleCaixa from '../pages/Financas/controleCaixa';
import ContasBancarias from '../pages/Financas/contasBancarias';
import Contas from '../pages/Financas/contas';
import Faturas from '../pages/Faturas';

import EmpresaConfig from '../pages/Configuracoes/EmpresaConfig';
import SiteConfig from '../pages/Configuracoes/Site';

import Header from '../componentes/Header';
import MensagesBar from '../componentes/MensagesBar';

export default function Rotas() {
    
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Header/>
            <MensagesBar />
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/recoveryAccount" component={RecoveryAccount} />
                <Route exact path="/register" component={Login} />

                <Route exact path="/email/inbox" component={EmailInbox} />

                <Route exact path="/resumo" component={Resumo} />
                <Route exact path="/cadastros/pessoas" component={Pessoas} />
                <Route exact path="/cadastros/pessoas/nova" component={NovaPessoa} />
                <Route exact path="/cadastro/pessoa/:codigo" component={PessoasDetalhes} />

                <Route exact path="/cadastros/veiculos" component={Veiculos} />
                <Route exact path="/cadastros/veiculos/novo" component={NovoVeiculo} />
                <Route exact path="/cadastros/veiculos/novo/placa" component={NovoVeiculoPlacaV2} />
                <Route exact path="/cadastro/veiculo/:codigo" component={VeiculosDetalhes} />

                <Route exact path="/cadastros/contratos" component={Contratos} />
                <Route exact path="/cadastro/contrato/:codigo" component={ContratoDetalhes} />

                <Route exact path="/cadastros/modelosmensagem" component={ModeloMensagem} />

                <Route exact path="/cadastros/listasTransmissoes" component={ListasTransmissoes} />
                
                <Route exact path="/cadastros/usuario/novo" component={NovoUsuario} />
                <Route exact path="/cadastro/usuario/:codigo" component={UsuarioDetalhes} />
                
                <Route exact path="/estoque" component={Estoque} />
                <Route exact path="/estoque/detalhes/:codigoCompra/:codigoVenda?" component={EstoqueDetalhes} />
                <Route exact path="/estoque/compra/nova/:codigo?" component={NovaCompra} />
                <Route exact path="/estoque/venda/nova/:codigo?" component={NovaVenda} />

                <Route exact path="/financas/fluxoCaixa" component={FluxoCaixa} />
                <Route exact path="/financas/controleCaixa" component={ControleCaixa} />
                <Route exact path="/financas/contasBancarias" component={ContasBancarias} />
                <Route exact path="/financas/contas" component={Contas} />
                <Route exact path="/financas/cartoes" component={FluxoCaixa} />
                <Route exact path="/financas/cheques" component={FluxoCaixa} />
                <Route exact path="/financas/notasPromissorias" component={FluxoCaixa} />

                <Route exact path="/faturas" component={Faturas} />
                
                <Route exact path="/empresa/config" component={EmpresaConfig} />                
                <Route exact path="/empresa/site" component={SiteConfig} />                

            </Switch>
        </Router>
    );
}