import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Backdrop from '@mui/material/Backdrop';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';
import ButtonCirclePrimary from '../../../../componentes/ButtonCirclePrimary';
import ButtonCircleDanger from '../../../../componentes/ButtonCircleDanger';

import { DataMask, CpfMask, CnpjMask } from '../../../../vendor/mascaras';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

import ModalNovoEndereco from './modalNovoEndereco';
import ModalEditEndereco from './modalEditEndereco';
import ModalNovoTelefone from './modalNovoTelefone';
import ModalEditTelefone from './modalEditTelefone';

export default function ModalDetalhesPessoa({codigo, visiblemodal, changemodal, refreshDetalhesCompraVenda=false}) {
  
  let IconImage = props => (
    <svg id="image" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 70 70">
        <g id="Grupo_305" data-name="Grupo 305">
        <g id="Grupo_304" data-name="Grupo 304">
            <path id="Caminho_154" data-name="Caminho 154" d="M63.438,0H6.563A6.562,6.562,0,0,0,0,6.563V63.438A6.562,6.562,0,0,0,6.563,70H63.438A6.562,6.562,0,0,0,70,63.438V6.563A6.562,6.562,0,0,0,63.438,0Zm2.188,63.438a2.187,2.187,0,0,1-2.187,2.188H6.563a2.187,2.187,0,0,1-2.187-2.187V6.563A2.187,2.187,0,0,1,6.563,4.375H63.438a2.187,2.187,0,0,1,2.188,2.188Z" fill="#9c9c9c"/>
            <path id="Caminho_155" data-name="Caminho 155" d="M116.747,245.136a2.187,2.187,0,0,0-3.084,0l-5.031,5.031-9.384-9.384a2.187,2.187,0,0,0-3.084,0L80.85,256.1a2.188,2.188,0,0,0,1.531,3.719h43.75a2.187,2.187,0,0,0,1.553-3.741Z" transform="translate(-69.257 -207.314)" fill="#9c9c9c"/>
            <ellipse id="Elipse_16" data-name="Elipse 16" cx="6.5" cy="7" rx="6.5" ry="7" transform="translate(35 17)" fill="#9c9c9c"/>
        </g>
        </g>
    </svg>
  );

  let IconSave = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 24.15 24.15">
        <path id="floppy-disk" d="M22.97,3.037,21.113,1.179A4.02,4.02,0,0,0,18.267,0H2.767A2.767,2.767,0,0,0,0,2.767V21.383A2.767,2.767,0,0,0,2.767,24.15H21.383a2.767,2.767,0,0,0,2.767-2.767V5.882A4.02,4.02,0,0,0,22.97,3.037ZM4.025,6.289V3.773a.755.755,0,0,1,.755-.755H15.345a.755.755,0,0,1,.755.755V6.289a.755.755,0,0,1-.755.755H4.78A.755.755,0,0,1,4.025,6.289Zm8.05,13.836A4.528,4.528,0,1,1,16.6,15.6,4.529,4.529,0,0,1,12.075,20.125Zm0,0" transform="translate(0)" fill="#fff"/>
    </svg>
  )

  let IconNoEndereco = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 127.142 157.237">
        <g id="endereco-residencial" transform="translate(-48.998)">
        <path id="Caminho_188" data-name="Caminho 188" d="M183,139.632v32.795h7.985V157.378a4.607,4.607,0,0,1,4.607-4.607h19.655a4.607,4.607,0,0,1,4.607,4.607v15.048h7.985V139.632l-22.419-22.419Zm31.939.549a4.607,4.607,0,0,1-4.607,4.607H200.5a4.607,4.607,0,1,1,0-9.213h9.827A4.607,4.607,0,0,1,214.936,140.181Z" transform="translate(-92.847 -81.216)" fill="#4a4a4a"/>
        <path id="Caminho_189" data-name="Caminho 189" d="M239,263h10.442v10.442H239Z" transform="translate(-131.649 -182.231)" fill="#4a4a4a"/>
        <path id="Caminho_190" data-name="Caminho 190" d="M112.569,0C76.755,0,48.089,29.789,49.02,65.555a63.557,63.557,0,0,0,16.221,40.755l.018-.019a63.465,63.465,0,0,0,92.658,2.09l.018.028a63.619,63.619,0,0,0,18.183-42.854C177.05,29.726,148.318,0,112.569,0Zm40.11,69.592a4.606,4.606,0,0,1-6.515,0L144.2,67.628V95.817a4.607,4.607,0,0,1-4.607,4.607H85.543a4.607,4.607,0,0,1-4.607-4.607V67.629l-1.964,1.964a4.607,4.607,0,0,1-6.515-6.515L90.764,44.772V29.482a4.607,4.607,0,1,1,9.213,0v6.077l9.334-9.334a4.607,4.607,0,0,1,6.515,0l36.853,36.853A4.606,4.606,0,0,1,152.679,69.592Z" transform="translate(0)" fill="#4a4a4a"/>
        <path id="Caminho_191" data-name="Caminho 191" d="M189.809,435.579a231.2,231.2,0,0,1,16.341,21.194h0l.178.261a4.609,4.609,0,0,0,7.616,0,230.945,230.945,0,0,1,16.515-21.451,72.873,72.873,0,0,1-40.65,0Z" transform="translate(-97.567 -301.81)" fill="#4a4a4a"/>
        </g>
    </svg>
  )

  let IconTelefoneCancel = pros =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 111.746 111.751">
        <g id="Ikon" transform="translate(-2.015 -2.045)">
        <path id="Caminho_192" data-name="Caminho 192" d="M28.229,39.779,10.636,9.307,16.28,3.663a5.624,5.624,0,0,1,8.838,1.229l11.67,20.9a5.585,5.585,0,0,1-1.04,6.789C35.513,32.9,28.386,39.38,28.229,39.779Z" transform="translate(7.451 0)" fill="#4a4a4a"/>
        <path id="Caminho_193" data-name="Caminho 193" d="M78.11,60.146,72.33,65.925,41.391,48.394c.42.222,7.49-7.411,7.939-7.732a5.6,5.6,0,0,1,6.7-.959L76.875,51.3a5.625,5.625,0,0,1,1.234,8.843Z" transform="translate(34.033 31.94)" fill="#4a4a4a"/>
        <path id="Caminho_194" data-name="Caminho 194" d="M68.991,76.342l34.635,19.626L98.2,101.4a26.732,26.732,0,0,1-31.56,4.546c-1.9-.594-14.467-5.1-35.384-26.017S5.831,46.442,5.238,44.538A26.724,26.724,0,0,1,9.783,12.98l5.574-5.574L34.6,40.728c.37,4.518,3.208,11.782,13.641,22.215,9.4,9.4,16.2,12.607,20.753,13.4Z" transform="translate(0 4.633)" fill="#4a4a4a"/>
        <path id="Caminho_195" data-name="Caminho 195" d="M77.308,42.763c6.784,7.118-3.42,17.331-10.546,10.545L55.257,41.8,43.75,53.308c-7.117,6.784-17.332-3.419-10.545-10.545L44.712,31.257,33.206,19.75c-6.784-7.118,3.42-17.331,10.546-10.545L55.257,20.712,66.763,9.205C73.88,2.421,84.1,12.625,77.308,19.75L65.8,31.257Z" transform="translate(25.031 4.262)" fill="#4a4a4a"/>
        </g>
    </svg>
  )

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 26.723 26.721">
        <path id="adicionar" d="M24.84,11.635H18.547a.461.461,0,0,1-.461-.461V4.88A3.348,3.348,0,0,0,15.033,1.5a3.231,3.231,0,0,0-2.392.881,3.2,3.2,0,0,0-1.005,2.34v6.45a.461.461,0,0,1-.461.461H4.882A3.349,3.349,0,0,0,1.5,14.688a3.225,3.225,0,0,0,3.221,3.4h6.45a.461.461,0,0,1,.461.461v6.293a3.334,3.334,0,0,0,3.228,3.382A3.224,3.224,0,0,0,18.086,25v-6.45a.461.461,0,0,1,.461-.461H25a3.225,3.225,0,0,0,3.221-3.4A3.351,3.351,0,0,0,24.84,11.635Z" transform="translate(-1.5 -1.5)" fill="#fff"/>
    </svg>
  )

  let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 21.241 28.294">
        <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M85.059,9.084H71.973l5.86-5.86a.829.829,0,0,0,0-1.172L76.348.567a1.934,1.934,0,0,0-2.735,0L72.086,2.094,71.3,1.312a2.213,2.213,0,0,0-3.126,0L65.13,4.36a2.213,2.213,0,0,0,0,3.126l.782.782L64.385,9.795a1.934,1.934,0,0,0,0,2.735l1.485,1.485a.829.829,0,0,0,1.172,0l2.1-2.1V24.7a3.592,3.592,0,0,0,3.592,3.592h8.732A3.592,3.592,0,0,0,85.059,24.7ZM67.084,7.1,66.3,6.314a.553.553,0,0,1,0-.782L69.35,2.484a.553.553,0,0,1,.782,0l.782.782Zm7.158,16.557a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Z" transform="translate(0 0)" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M305.186,86.046a1.384,1.384,0,0,0-.919,1.152h7.954V85.82a1.382,1.382,0,0,0-1.825-1.309L305.2,86.04Z" transform="translate(-227.162 -79.771)" fill="#fff"/>
        </g>
    </svg>
  )


  let history = useHistory();

  const [tabPage, settabPage] = useState("1");
  const [loaderPage, setloaderPage] = useState(false);

  const [dadosPessoa, setdadosPessoa] = useState([]);
  const [fotoPessoa, setfotoPessoa] = useState();

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const [dataNascimento, setdataNascimento] = useState(null);
  const [dataFundacao, setdataFundacao] = useState(null);

  const [showModalNovoEndereco, setshowModalNovoEndereco] = useState(false);
  const [showModalEditEndereco, setshowModalEditEndereco] = useState(false);
  const [enderecoDetalhes, setenderecoDetalhes] = useState();

  const [showModalNovoTelefone, setshowModalNovoTelefone] = useState(false);
  const [showModalEditTelefone, setshowModalEditTelefone] = useState(false);
  const [telefoneDetalhes, settelefoneDetalhes] = useState();

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [progressLoadFoto, setprogressLoadFoto] = useState(0);

  const [enderecos, setenderecos] = useState([]);
  const [enderecosSelecionados, setenderecosSelecionados] = useState([]);
  const [pageSizeDataGridEnderecos, setpageSizeDataGridEnderecos] = useState(10);
  const [loadingDataGridEnderecos, setloadingDataGridEnderecos] = useState(true);

  const [telefones, settelefones] = useState([]);
  const [telefonesSelecionados, settelefonesSelecionados] = useState([]);
  const [pageSizeDataGridTelefones, setpageSizeDataGridTelefones] = useState(10);
  const [loadingDataGridTelefones, setloadingDataGridTelefones] = useState(true);
 
  const [habilitadoLead, sethabilitadoLead] = useState(false);

  const handleChangeDataNascimento = (data) => {
    setdataNascimento(data);
  };

  const handleChangeDataFundacao = (data) => {
    setdataFundacao(data);
  };
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };

  const getEnderecosPessoa = async(idPessoa)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    //ENDEREÇOS
    setloadingDataGridEnderecos(true);
    let enderecosPessoa = await api.post('listagemEnderecosPesssoa', {
      "pessoaId": idPessoa
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    setenderecos(enderecosPessoa.data);
    setloadingDataGridEnderecos(false);
  }

  const getTelefonesPessoa = async(idPessoa)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    //ENDEREÇOS
    setloadingDataGridTelefones(true);
    let telefonesPessoa = await api.post('listagemTelefonesPesssoa', {
      "pessoaId": idPessoa
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    settelefones(telefonesPessoa.data);
    setloadingDataGridTelefones(false);
  }

  const detalhesPessoa = async()=>{
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

    //   codigo === 'undefined' && history.push("/cadastros/pessoas");
  
      api.post('detalhesPessoa', {
          "codigo": codigo
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      }).then( async response => {
          
          let data = response.data;
          setdadosPessoa(response.data);

          data.foto && setfotoPessoa(data.foto);

          formik.setFieldValue('tipo', data.tipo);
          formik.setFieldValue('nome', data.nome);
          data.email && formik.setFieldValue('email', data.email);
          data.habilitadoLead && sethabilitadoLead(Boolean(data.habilitadoLead));
          data.rg && formik.setFieldValue('rg', data.rg);
          data.cpf && formik.setFieldValue('cpf', data.cpf);
          data.nascimento && setdataNascimento(data.nascimento);
          data.nacionalidade && formik.setFieldValue('nacionalidade', data.nacionalidade);
          data.nomePai && formik.setFieldValue('nomePai', data.nomePai);
          data.nomeMae && formik.setFieldValue('nomeMae', data.nomeMae);
          data.cnpj && formik.setFieldValue('cnpj', data.cnpj);
          data.representante && formik.setFieldValue('representante', data.representante);
          data.inscricaoestadual && formik.setFieldValue('inscricaoestadual', data.inscricaoestadual);
          data.inscricaomunicipal && formik.setFieldValue('inscricaomunicipal', data.inscricaomunicipal);
          data.dataFundacao && setdataFundacao(data.dataFundacao);
          data.obs && formik.setFieldValue('obs', data.obs);


          getEnderecosPessoa(data.id);
          getTelefonesPessoa(data.id);

      }).catch((error) => {
        //   history.push("/cadastros/pessoas");
      });
      
      
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    // console.log(permissoes.data.pessoas[3].update);
    setpermissoesdoUsuario(permissoes.data.pessoas);
  }

  const detalhesEndereco = async(id)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let detalhes = await api.post('detalhesEnderecoPessoa', {
      "enderecoId": id,
      "pessoaId": dadosPessoa.id,
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });
    
    setenderecoDetalhes(detalhes.data);
    setshowModalEditEndereco(true);

  }

  const detalhesTelefone = async(id)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let detalhes = await api.post('detalhesTelefonePessoa', {
      "telefoneId": id,
      "pessoaId": dadosPessoa.id,
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });
    
    settelefoneDetalhes(detalhes.data);
    setshowModalEditTelefone(true);

  }

  const validationSchema = yup.object({
    tipo: yup
      .number('Seleciona o tipo de pessoa')
      .required('Tipo Obrigatório'),
    nome: yup
      .string('Insira o nome da pessoa')
      .required('Nome Obrigatório'),
    // email: yup
    //   .string('Insira seu E-mail')
    //   .email('Insira um E-mail válido')
    //   .required('E-mail Obrigatório'),
    rg: yup
      .string('Insira o RG'),
    cpf: yup
      .string('Insira o CPF'),
    // nascimento: yup
    //   .string('Insira a data de nascimento'),
    cnpj: yup
      .string('Insira o CNPJ'),
    representante: yup
      .string('Insira o nome do representante da empresa'),
    inscricaoestadual: yup
      .string('Insira a inscrição estadual da empresa'),
    inscricaomunicipal: yup
      .string('Insira a inscrição municipal da empresa'),
  });

  const formik = useFormik({
    initialValues: {
      tipo: '',
      nome: '',
      email: '',
      rg: '',
      cpf: '',
      nascimento: '',
      nacionalidade: '',
      nomePai: '',
      nomeMae: '',
      cnpj: '',
      representante: '',
      inscricaoestadual: '',
      inscricaomunicipal: '',
      dataFundacao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

   
      await api.put(`updatePessoa`, {
          "id": dadosPessoa.id,
          "tipo": values.tipo,
          "cliente": dadosPessoa.cliente,
          "habilitadoLead": Number(habilitadoLead),
          "fornecedor": dadosPessoa.fornecedor,
          "usuario": dadosPessoa.usuario,
          "nome": values.nome,
          "email": values.email ? values.email : null,
          "obs": values.obs ? values.obs : null,
          "rg": values.rg ? values.rg : null,
          "cpf": values.cpf ? values.cpf : null,
          "nascimento": dataNascimento ? dataNascimento : null,
          "nacionalidade": values.nacionalidade ? values.nacionalidade : null,
          "nomePai": values.nomePai ? values.nomePai : null,
          "nomeMae": values.nomeMae ? values.nomeMae : null,
          "cnpj": values.cnpj ? values.cnpj : null,
          "representante": values.representante ? values.representante : null,
          "inscricaoestadual": values.inscricaoestadual ? values.inscricaoestadual : null,
          "inscricaomunicipal": values.inscricaomunicipal ? values.inscricaomunicipal : null,
          "dataFundacao": dataFundacao ? dataFundacao : null
      },
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      setalertSucess(true);        
      setalertSucessMsg(`Dados do(a) ${values.nome} Atualizados com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);
      
    }
  });

  let handleFotoPessoa = async(e)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let imagem = e.target.files[0];

    let formData = new FormData();
    formData.append('pessoaId', dadosPessoa.id);
    formData.append('image', imagem);

    if(imagem.type === 'image/png' || imagem.type === 'image/gif' || imagem.type === 'image/jpeg'){
      console.log("upp")
      await api.put("uploadFotoPessoa", formData, {
        headers: { 
          "Content-type": "multipart/form-data",
          "Authorization": `Bearer ${auth.token}`
        },
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          setprogressLoadFoto(progress)
        },
      })

      setprogressLoadFoto(0);

      //Set Foto Selecionada In Preview
      const reader = new FileReader();
      reader.onload = () =>{
        if(reader.readyState === 2){
          setfotoPessoa(reader.result)
        }
      }
      reader.readAsDataURL(e.target.files[0])
    }else{
      setalertErrorMsg('Foto inválida, Apenas os formatos .png, .jpg e .jpeg são permitidos!')
      setalertError(true);
    }

  }

  useEffect(async ()=>{ 

    if(visiblemodal){
        setloaderPage(true);
        settabPage("1");
        setshowModalNovoEndereco(false);
        setshowModalEditEndereco(false);
        setshowModalNovoTelefone(false);
        setshowModalEditTelefone(false);
        setfotoPessoa(false);
        formik.resetForm();

        codigo && await detalhesPessoa();
        codigo && await permissoesUsuario();
        setloaderPage(false);
    }

    refreshDetalhesCompraVenda != false && refreshDetalhesCompraVenda();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visiblemodal]);


  const Input = styled('input')({
    display: 'none',
  });

  const handleDeleteEndereco = async()=>{
    
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar o(s) Endereço(s) selecionado(s)?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let enderecosid = [];

        for await (let id of enderecosSelecionados) {
          let dados = { id: id }
          enderecosid.push(dados);
        }
        
        await api.delete('deleteEnderecoPesssoa', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "pessoaId": dadosPessoa.id, "enderecosIds": enderecosid }
        });

        await getEnderecosPessoa(dadosPessoa.id);

        Swal.fire(
          'Deletado!',
          'Endereço(s) deletado(s) com sucesso!',
          'success'
        )
      }
    })

  }

  const handleStatusEndereco = async(event, id)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.put('definirPrincipalEnderecoPesssoa', {
      "id": id,
      "pessoaId": dadosPessoa.id,
      "principal": event.target.checked
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    await getEnderecosPessoa(dadosPessoa.id);

  }
 
  const columnsDataGridEnderecos = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'principal',
      headerName: 'Principal',
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params) => ( //renderiza componente
        <p>
          <Switch
            disabled={!permissoesdoUsuario[3]?.update}
            checked={params.value ? true : false}
            onChange={(event)=>{ handleStatusEndereco(event, params.id) }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </p>
      ),
    },
    {
      field: 'cep',
      headerName: 'Cep',
      flex: 1,
      minWidth: 100,
      editable: false,
      valueFormatter: (params) => {
        return params.value
      },
    },
    {
      field: 'rua',
      headerName: 'Rua',
      flex: 1,
      minWidth: 250,
      editable: false,
      valueFormatter: (params) => {
        return params.value
      },
    },
    {
      field: 'numero',
      headerName: 'Número',
      flex: 1,
      minWidth: 80,
      editable: false,
      valueFormatter: (params) => {
        return params.value
      },
    },
    {
      field: 'bairro',
      headerName: 'Bairro',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return params.value
      },
    },
    {
      field: 'cidade',
      headerName: 'Cidade',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return params.value
      },
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      minWidth: 50,
      editable: false,
      valueFormatter: (params) => {
        return params.value
      },
    },
  ];

  
  const handleDeleteTelefone = async()=>{
  
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar o(s) Telefone(s) selecionado(s)?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let telefonesid = [];

        for await (let id of telefonesSelecionados) {
          let dados = { id: id }
          telefonesid.push(dados);
        }
        
        await api.delete('deleteTelefonePesssoa', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "pessoaId": dadosPessoa.id, "telefonesIds": telefonesid }
        });

        await getTelefonesPessoa(dadosPessoa.id);

        Swal.fire(
          'Deletado!',
          'Telefone(s) deletado(s) com sucesso!',
          'success'
        )
      }
    })

  }

  const handlePrincipalTelefone = async(event, id)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.put('definirPrincipalTelefonePesssoa', {
      "id": id,
      "pessoaId": dadosPessoa.id,
      "principal": event.target.checked
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    await getTelefonesPessoa(dadosPessoa.id);

  }

  const handleIsWhatsAppTelefone = async(event, id)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.put('definirWhatsAppTelefonePesssoa', {
      "id": id,
      "pessoaId": dadosPessoa.id,
      "whatsapp": event.target.checked
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    await getTelefonesPessoa(dadosPessoa.id);

  }

  const columnsDataGridTelefones = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'principal',
      headerName: 'Principal',
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params) => ( //renderiza componente
        <p>
          <Switch
            disabled={!permissoesdoUsuario[3]?.update}
            checked={params.value ? true : false}
            onChange={(event)=>{ handlePrincipalTelefone(event, params.id) }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </p>
      ),
    },
    {
      field: 'whatsapp',
      headerName: 'whatsapp',
      flex: 1,
      minWidth: 100,
      editable: false,
      renderCell: (params) => ( //renderiza componente
        <p>
          <Switch
            disabled={!permissoesdoUsuario[3]?.update}
            checked={params.value ? true : false}
            onChange={(event)=>{ handleIsWhatsAppTelefone(event, params.id) }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </p>
      ),
    },
    {
      field: 'numero',
      headerName: 'Número',
      flex: 2,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return params.value
      },
    },
    {
      field: 'obs',
      headerName: 'obs',
      flex: 2,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return params.value
      },
    },
  ];
  

  function nenhumEnderecoEncontrado(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          <IconNoEndereco/>
          <p>Nenhum endereço encontrado</p>
        </div>
      </GridOverlay>
    )
  }

  function nenhumTelefoneEncontrado(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          <IconTelefoneCancel/>
          <p>Nenhum telefone encontrado</p>
        </div>
      </GridOverlay>
    )
  }

  

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }


  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue); 
  };

  const handleHabilitadosLeads = async(props)=>{
    let hbleads = props.target.checked;
    sethabilitadoLead(hbleads);
  }

  return(
    <>
    <ModalNovoEndereco pessoaid={dadosPessoa.id} visible={showModalNovoEndereco} change={setshowModalNovoEndereco}  refreshEnderecos={getEnderecosPessoa} />
    <ModalEditEndereco permissoesUpdate={permissoesdoUsuario[3]?.update} pessoaid={dadosPessoa.id} detalhes={enderecoDetalhes} visible={showModalEditEndereco} change={setshowModalEditEndereco}  refreshEnderecos={getEnderecosPessoa} />

    <ModalNovoTelefone pessoaid={dadosPessoa.id} visible={showModalNovoTelefone} change={setshowModalNovoTelefone}  refreshTelefones={getTelefonesPessoa} />
    <ModalEditTelefone permissoesUpdate={permissoesdoUsuario[3]?.update} pessoaid={dadosPessoa.id} detalhes={telefoneDetalhes} visible={showModalEditTelefone} change={setshowModalEditTelefone}  refreshTelefones={getTelefonesPessoa} />

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
    <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
    </Alert>
    </Snackbar>

    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
    <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
    </Alert>
    </Snackbar>

    <Dialog open={visiblemodal} maxWidth='lg' fullWidth={true} onClose={()=>{ changemodal(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Detalhes Pessoa
            <IconButton
              aria-label="close"
              onClick={()=>{ changemodal(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} >
            <TabContext value={tabPage}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}> 
                <Tabs value={tabPage} onChange={handleChangeTabPage} aria-label="estoque-tabs" textColor="primary" indicatorColor="primary" variant="scrollable">
                    <Tab label="Cadastro" value="1" />
                    <Tab label="Telefones" value="2" />
                    <Tab label="Endereços" value="3" />
                </Tabs>
                </Box>

                <TabPanel value="1" style={{width: '100%'}}>
                    
                    {
                        dadosPessoa.length === 0 ?

                        <Grid container item spacing={0} style={{width: '100%'}}>
                            <Grid container spacing={2} >
                            <Grid item xs={12} md={2.5}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                            </Grid>
                        
                            <Grid container spacing={1} item xs={12} md={9.5}>
                                <Grid item xs={12} md={12}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            
                            </Grid>
                        </Grid>

                        :
                        <>
                            <Grid container item spacing={0}>

                                <Grid container spacing={2} >
                                <Grid item xs={12} md={2.5}>
                                    <label htmlFor="button-file-foto">
                                    {
                                        permissoesdoUsuario[3]?.update &&
                                        <Input accept="image/png, image/gif, image/jpeg" id="button-file-foto" onChange={handleFotoPessoa} type="file" />
                                    }

                                    {
                                        progressLoadFoto.length > 0 ? 
                                        
                                        <div className='box-progress-foto-pessoa'><CircularProgressWithLabel value={progressLoadFoto} /></div>
                                        
                                        :
                                        <>
                                        {
                                            !fotoPessoa ?
                                            <div className='icon-upload-foto'>
                                            <IconImage/>
                                            </div>

                                            :

                                            <div className='box-foto-pessoa' style={{ backgroundImage: `url(${fotoPessoa})` }}></div>

                                        }
                                        </>
                                    }

                                    </label>
                                </Grid>

                                <Grid container spacing={0} item xs={12} md={9.5}>
                                    <Grid item xs={12} md={12} >
                                    <TextField
                                        fullWidth
                                        id="nome"
                                        name="nome"
                                        label="nome"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.nome}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.nome && Boolean(formik.errors.nome)}
                                        helperText={formik.touched.nome && formik.errors.nome}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                    <FormControl sx={{ m: 0, width: '100%' }} >
                                        <InputLabel id="label-tipo">Tipo</InputLabel>
                                        <Select
                                        labelId="label-tipo"
                                        id="demo-simple-select"
                                        name="tipo"
                                        label="Tipo"
                                        placeholder='Tipo'
                                        value={formik.values.tipo}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                                        >
                                        <MenuItem value={1}>Física</MenuItem>
                                        <MenuItem value={2}>Jurídica</MenuItem>
                                        </Select>
                                        <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                                    </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                      <div style={{ 'marginLeft': '10px' }}>
                                      <FormGroup>
                                        <FormControlLabel control={
                                          <Switch
                                            disabled={!permissoesdoUsuario[3]?.update}
                                            checked={habilitadoLead}
                                            onChange={handleHabilitadosLeads}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                          />
                                        } label="Habilitar p/ Leads" />
                                      </FormGroup>
                                      </div>
                                    </Grid>
                                </Grid>
                                </Grid>

                                {
                                formik.values.tipo === 1 ?

                                <Grid container spacing={2} rowSpacing={-1}>
                                    <Grid item xs={12} md={8}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="E-mail"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.email}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                        <DesktopDatePicker
                                        label="Data de Nascimento"
                                        inputFormat="dd/MM/yyyy"
                                        name="nascimento"
                                        value={dataNascimento}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={handleChangeDataNascimento}
                                        allowSameDateSelection={true}
                                        renderInput={(params) => <TextField style={{ width: '100%', marginTop: '15px'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        id="nacionalidade"
                                        name="nacionalidade"
                                        label="Nacionalidade"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.nacionalidade}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.nacionalidade && Boolean(formik.errors.nacionalidade)}
                                        helperText={formik.touched.nacionalidade && formik.errors.nacionalidade}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        id="rg"
                                        name="rg"
                                        label="RG"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.rg}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.rg && Boolean(formik.errors.rg)}
                                        helperText={formik.touched.rg && formik.errors.rg}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        id="cpf"
                                        name="cpf"
                                        label="CPF"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.cpf}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                                        helperText={formik.touched.cpf && formik.errors.cpf}
                                        InputProps={{
                                        inputComponent: CpfMask,
                                        }}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="nomePai"
                                        name="nomePai"
                                        label="Nome do pai"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.nomePai}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.nomePai && Boolean(formik.errors.nomePai)}
                                        helperText={formik.touched.nomePai && formik.errors.nomePai}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="nomeMae"
                                        name="nomeMae"
                                        label="Nome da mãe"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.nomeMae}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.nomeMae && Boolean(formik.errors.nomeMae)}
                                        helperText={formik.touched.nomeMae && formik.errors.nomeMae}
                                    />
                                    </Grid>

                                </Grid>

                                :

                                <Grid container spacing={2} rowSpacing={-1}>
                                    <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        name="email"
                                        label="E-mail"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.email}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                    </Grid>
                                    
                                    <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        id="cnpj"
                                        name="cnpj"
                                        label="CNPJ"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.cnpj}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                                        helperText={formik.touched.cnpj && formik.errors.cnpj}
                                        InputProps={{
                                        inputComponent: CnpjMask,
                                        }}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        id="inscricaoestadual"
                                        name="inscricaoestadual"
                                        label="Inscrição estadual"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.inscricaoestadual}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.inscricaoestadual && Boolean(formik.errors.inscricaoestadual)}
                                        helperText={formik.touched.inscricaoestadual && formik.errors.inscricaoestadual}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        id="inscricaomunicipal"
                                        name="inscricaomunicipal"
                                        label="Inscrição municipal"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.inscricaomunicipal}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.inscricaomunicipal && Boolean(formik.errors.inscricaomunicipal)}
                                        helperText={formik.touched.inscricaomunicipal && formik.errors.inscricaomunicipal}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                        <DesktopDatePicker
                                        label="Data de Fundação"
                                        inputFormat="dd/MM/yyyy"
                                        name="dataFundacao"
                                        value={dataFundacao}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={handleChangeDataFundacao}
                                        allowSameDateSelection={true}
                                        renderInput={(params) => <TextField style={{ width: '100%', marginTop: '15px'}} {...params} />}
                                        />
                                    </LocalizationProvider>
                                    </Grid>
                                      
                                    <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        id="representante"
                                        name="representante"
                                        label="Nome completo do Representante"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.representante}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.representante && Boolean(formik.errors.representante)}
                                        helperText={formik.touched.representante && formik.errors.representante}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        id="rg"
                                        name="rg"
                                        label="RG do Representante"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.rg}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.rg && Boolean(formik.errors.rg)}
                                        helperText={formik.touched.rg && formik.errors.rg}
                                    />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        id="cpf"
                                        name="cpf"
                                        label="CPF do Representante"
                                        margin="normal"
                                        type="text"
                                        value={formik.values.cpf}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                                        helperText={formik.touched.cpf && formik.errors.cpf}
                                        InputProps={{
                                        inputComponent: CpfMask,
                                        }}
                                    />
                                    </Grid>
                                    


                                </Grid>
                                
                                }

                                <Grid container spacing={0} item xs={12} md={12}>
                                    <Grid item xs={12} md={12} >
                                    <TextField
                                        fullWidth
                                        id="obs"
                                        name="obs"
                                        label="Obs"
                                        margin="normal"
                                        multiline
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        rows={1}
                                        defaultValue={formik.values.obs}
                                        onChange={formik.handleChange}
                                        error={formik.touched.obs && Boolean(formik.errors.obs)}
                                        helperText={formik.touched.obs && formik.errors.obs}
                                    />
                                    </Grid>
                                </Grid> 

                            </Grid>
                        </>
                    }
                </TabPanel>

                <TabPanel value="2" style={{ width: '100%'}}>
                <div style={{ width: '100%' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>

                        {
                          permissoesdoUsuario[1]?.delete &&
                          
                          <>
                          {
                            telefonesSelecionados.length > 0 &&

                            <div style={{ marginRight: '10px' }}>
                              <ButtonCircleDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteTelefone() }}/>
                            </div>
                          }
                          </>

                        }

                        {
                          permissoesdoUsuario[2]?.insert &&

                          <>
                          {
                            dadosPessoa.id &&  <ButtonCirclePrimary icon={<IconPlus/>} onClick={()=>{ setshowModalNovoTelefone(true) }}/>
                          }
                          </>

                        }
                      </div>

                      <DataGrid
                        sx={{ minHeight: '300px' }}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        autoHeight
                        rows={telefones}
                        columns={columnsDataGridTelefones}
                        pageSize={pageSizeDataGridTelefones}
                        onPageSizeChange={(newPageSize) => setpageSizeDataGridTelefones(newPageSize)}
                        rowsPerPageOptions={[10, 50, 100]} 
                        checkboxSelection={permissoesdoUsuario[3]?.update}
                        disableSelectionOnClick
                        editMode='row'
                        loading={loadingDataGridTelefones}
                        disableColumnMenu={true}
                        onSelectionModelChange={(props)=>{
                            settelefonesSelecionados(props)
                        }}
                        // onRowClick
                        onCellClick={(props)=>{
                            if(props.field !== "__check__" && props.field !== "principal" && props.field !== "whatsapp"){
                            detalhesTelefone(props.id);
                            }
                        }}
                        components={{
                            NoRowsOverlay: nenhumTelefoneEncontrado,
                        }}
                        />
                    </div>
                </TabPanel>

                <TabPanel value="3" style={{ width: '100%'}}>
                    <div style={{ width: '100%' }}>
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>

                        {
                          permissoesdoUsuario[1]?.delete &&
                          
                          <>
                          {
                            enderecosSelecionados.length > 0 &&

                            <div style={{ marginRight: '10px' }}>
                              <ButtonCircleDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteEndereco() }}/>
                            </div>
                          }
                          </>

                        }

                        {
                          permissoesdoUsuario[2]?.insert &&

                          <>
                          {
                            dadosPessoa.id &&  <ButtonCirclePrimary icon={<IconPlus/>} onClick={()=>{ setshowModalNovoEndereco(true) }}/>
                          }
                          </>

                        }

                      </div>

                      <DataGrid
                        sx={{ minHeight: '300px', width: '100%' }}
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        autoHeight
                        rows={enderecos}
                        columns={columnsDataGridEnderecos}
                        pageSize={pageSizeDataGridEnderecos}
                        onPageSizeChange={(newPageSize) => setpageSizeDataGridEnderecos(newPageSize)}
                        rowsPerPageOptions={[10, 50, 100]} 
                        checkboxSelection={permissoesdoUsuario[3]?.update}
                        disableSelectionOnClick
                        editMode='row'
                        loading={loadingDataGridEnderecos}
                        disableColumnMenu={true}
                        onSelectionModelChange={(props)=>{
                            setenderecosSelecionados(props)
                        }}
                        // onRowClick
                        onCellClick={(props)=>{
                            if(props.field !== "__check__" && props.field !== "principal"){
                            detalhesEndereco(props.id);
                            }
                        }}
                        components={{
                            NoRowsOverlay: nenhumEnderecoEncontrado,
                        }}
                        />
                    </div>
                </TabPanel>

            </TabContext>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ changemodal(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Salvar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}